import http from './http'

export function close(data) {
    return http.post('/index/ship/close', data)
}
export function getInfo(data) {
    return http.post('/index/ship/getInfo', data)
}
export function start(data) {
    return http.post('/index/ship/start', data)
}
export function getTime(data) {
    return http.post('/index/ship/getTime', data)
}
export function overtime(data) {
    return http.post('/index/ship/overtime', data)
}
export function finish(data) {
    return http.post('/index/ship/finish', data)
}
export function getUser(data) {
    return http.post('/index/ship/getUser',data);
}
export function getList(data) {
    return http.post('/index/ship/getList', data)
}
export function getRole(data) {
    return http.post('/index/ship/getRole', data)
}
export function nowFixer(data) {
    return http.post('/index/ship/nowFixer', data)
}
export function getAnalyze(data) {
    return http.post('/index/ship/getAnalyze', data)
}
export function analyze(data) {
    return http.post('/index/ship/analyze', data)
}
export function getroleAndMember(data) {
    return http.post('/index/ship/getroleAndMember', data)
}
export function comment(data) {
    return http.post('/index/ship/comment', data)
}
